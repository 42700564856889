.quick-steps {
    position: relative;
    margin-bottom: 50px;
    .step-img {
        position: absolute;
        width: 200px;
        height: auto;
    }
    .step-1 {
        left: 25%;
    }
    .step-2 {
        left: calc(25% + 170px);
    }
    .step-3 {
        left: calc(25% + 170px + 170px);
    }
}
