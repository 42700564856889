.toggle-switch {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 1.25em;
}

.toggle-switch input[type='checkbox'] {
    display: none;
}

.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    border-radius: 1.2em;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e7ebfb;
    transition: background-color 0.2s ease;
    border: 1px solid #e7ebfb;
    padding: 5px;
}

.toggle-switch .switch::before {
    position: absolute;
    top: 2px;
    left: 2.5px;
    content: '';
    width: 19px;
    height: 19px;
    background-color: #626262;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(13px);
    background-color: $theme-peacock;
}

.toggle-switch input[type='checkbox']:checked + .switch {
    // background-color: $theme-peacock;
    background-color: #e7ebfb;
}
