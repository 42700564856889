.form-select {
    color: $theme-black !important;
    border: 1px solid $theme-black;
    border-radius: 5px;

    //height: 40px;
    //padding-right: 35px;
    &:focus {
        border-color: $theme-black !important;
        outline: 0;
        box-shadow: none;
    }

    &::placeholder {
        font-size: 14px;
        color: #cccccc;
    }
}

// .error {
//   border: none !important;
// }

.invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $theme-error;
    font-family: open-sans-bold, sans-serif;
}

select {
    position: relative;
    width: 100%;
    border: none;
    font-family: 'Manrope-Regular';
    box-shadow: none !important;
    outline: none !important;
    background-color: $White-Color;

    &::placeholder {
        color: $theme-placeholder;
    }
}

.graphDropdownWidth .select__value-container {
    padding: 0px 5px !important;
}
.select__value-container {
    padding: 1em !important;
}

.ui-searhselect-inputclass {
    background-color: red !important;
    border: 10px !important;
    color: 'red' !important;
}

// .select__value-container{}
.select__input-container {
    margin: 0 !important;
    padding: 0 !important;
}

// .top {
//   z-index: 2;
// }

.select__menu {
    z-index: 3 !important;
}

.custom-search-select-option {
    padding: 5px 8px 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .custom-search-button {
        border: 1px solid #828181;
        width: 100%;
        color: #133f7d;
        height: 30px;
        background-color: white;
        padding-left: 10px;
        text-align: left;
    }
}
