@mixin instructions-save-cancel-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}
.question_cross_margin {
    margin-top: calc(18px + 0.25rem);
}
.undo-icon {
    position: absolute;
    top: 60%;
    right: 10px;
    transform: translateY(-150%);
    cursor: pointer;
}
.save-icon {
    position: relative;
    top: 50%;
    cursor: pointer;
}
.question_content_container {
    // background-color: #fff;
    padding: 15px;
}
.instruction_container {
    background-color: #eeeff8;
    padding: 12px 1px 15px 16px;
    border-radius: 2px;
    margin-top: 10px;
    .info_container {
        font-weight: 600;
        font-size: 14px;
        line-height: 22.97px;
        color: #626262;
        .text {
            font-weight: 500;
        }
    }
    .info_line {
        height: 1px;
        background-color: $theme-gray;
    }
    .instructions_content {
        font-size: 12px;
        font-weight: 600;
        line-height: 19.69px;
        color: #000000;
        position: relative;
    }
}
.instruction_containerv2-edit-mode {
    background-color: #ffffff;
    border: 1px solid #000 !important;
}
.instruction_containerv2-normal-mode {
    background: #eeeff8;
}
.instruction_containerv2 {
    min-height: 225px;
    padding: 15px 1px 5px 16px;
    border-radius: 2px;
    border: 1px solid #eeeff8;
    .info_container {
        font-weight: 600;
        font-size: 14px;
        line-height: 22.97px;
        color: #626262;
        .text {
            font-weight: 700;
        }
    }

    .info_line {
        height: 1px;
        background-color: $theme-gray;
    }

    .instructions_content {
        font-size: 12px;
        font-weight: 600;
        line-height: 19.69px;
        color: #000000;
        position: relative;
        .edit-mode-buttons {
            position: absolute;
            top: -7px;
            right: 8px;
            display: flex;
            flex-basis: 150px;
            .edit-mode-save {
                @include instructions-save-cancel-buttons;
                padding: 7px 23px;
                background: #12b6bc;
                border: none;
                color: #fff;
                margin-right: 6px;
            }
            .edit-mode-cancel {
                @include instructions-save-cancel-buttons;
                padding: 7px 17px;
                border: 1px solid #12b6bc;
                background-color: #ffffff;
                color: #12b6bc;
            }
        }
    }

    .edit_icon_absolute {
        position: absolute;
        top: -7px;
        right: 8px;
        cursor: pointer;
    }
}
.question_item_container {
    display: flex;
    align-items: center;
    width: 100%;
}
.custom_question,
.coding_question {
    padding: 10px 40px 10px 10px;
    border-radius: 2px;
    border: 0.35px solid #121212;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    color: #000000;
    width: 100%;
    height: 100%;
    font-family: 'Manrope-Regular';
}
.pe-90 {
    padding-right: 90px;
}
.pe-115 {
    padding-right: 115px;
}
textarea.coding_question {
    padding: 10px;
    appearance: none;
    resize: unset;
    height: 130px;
    overflow-y: auto;
}
textarea.custom_question {
    appearance: none;
    resize: unset;
    height: 46px;
    overflow-y: auto;
}
.custom_question_input,
.custom_question_input:focus {
    border-radius: 2px;
    border: none !important;
    width: 100%;
    letter-spacing: normal;
    font-family: 'Manrope';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;
    overflow-y: hidden;
    outline: none;
}
.edit-input-container {
    height: 32px;
    border-bottom: 1px solid #121212 !important;
    border-radius: unset;
    .common-input {
        background-color: transparent;
        border-bottom: 1px solid #121212;
        height: 30px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(98, 98, 98, 1);
        width: 100px;
        padding: 5px;
    }
    .common-input-publicLink {
        background-color: transparent;
        border-bottom: 1px solid #121212;
        height: 30px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(98, 98, 98, 1);
        width: 100px;
        padding: 5px;
    }
}
.ai-icon {
    position: absolute;
    bottom: 12.5px;
    left: 10px;
}
.question_number {
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 19.12px;
    color: #000;
    margin-top: 5px;
}
.questions_item_container {
    .select-label-disabled {
        color: #626262 !important;
    }
    .question_title {
        font-size: 16px;
        font-weight: 700;
        line-height: 21.86px;
        color: #191919;
        font-family: 'Manrope';
        margin-left: 40px;
        margin-bottom: 10px;
    }
    .questions-container {
        margin-bottom: 13px;
        .edit-question {
            height: 46px;
            white-space: pre-wrap;
            padding-right: 70px;
            padding-left: 10px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            font-family: 'Manrope-Regular';
            font-size: 12px;
            font-weight: 400;
            line-height: 16.39px;
            color: #000000;
            border: 0.35px solid #121212;
            border-radius: 2px;
            overflow-y: hidden;
        }
        .question_item {
            background-color: #f2f2f2;
            padding: 5px 0 5px 10px;
            width: 450px;
            min-height: 46px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            code {
                color: #000;
                font-family: 'Manrope';
                font-size: 12px;
                font-weight: 400;
            }
        }
        .code {
            color: #000;
            font-family: 'Manrope';
            font-size: 12px;
            font-weight: 400;
        }
        .question-pre {
            white-space: pre-wrap;
            padding-right: 70px;
        }
    }
    .error {
        position: absolute;
        bottom: -10px;
        left: 40px;
    }
    .bottom-20 {
        bottom: -15px;
    }
    .general-question-error {
        bottom: -18px;
    }
    .left-0 {
        left: 0 !important;
    }
    .add-question {
        display: inline-block;
        color: #0d6efd;
        font-family: 'Manrope-SemiBold';
        font-size: 14px;
        cursor: pointer;
    }
    .buttons-container {
        justify-content: unset !important;
        margin-top: -3px;
        margin-bottom: 13px;
    }
    .select__control {
        height: 31px;
        min-height: 31px;
        .select__value-container {
            padding: 0 0.5em !important;
        }
    }
    .select__indicator-separator {
        display: none;
    }
    .select__indicators {
        height: 30px;
        svg {
            height: 14px;
        }
    }
}
.view_question_modal {
    min-height: unset !important;
    width: 80vw !important;
    max-width: 1000px !important;
}
.select-container-disabled {
    border-radius: 3px;
    background: #e7e7e7;
}
.select_disabled {
    .select__control {
        height: 31px;
        min-height: 31px;
        background: #e7e7e7 !important;
        .select__value-container {
            padding: 0 0.5em !important;
        }
    }
}
.question_icon_container {
    position: absolute;
    right: 10px;
    margin-top: 3px;
    display: flex;
    align-items: center;
    // top: 10px;
}
.top-10 {
    top: 10px;
}
.top-10 {
    top: 10px;
}
.top-10 {
    top: 10px;
}
.question_icon_container img {
    cursor: pointer;
}
.align-center {
    display: flex;
    align-items: center;
}
.align-start {
    display: flex;
    align-items: flex-start;
}
.select-time-container {
    height: 40px;
    margin-top: -10px;
    .select-box {
        width: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 21px;
        border-radius: 3px !important;
        border: 0.35px solid #000;
        background: rgba(238, 239, 248, 0);
        .select__indicator {
            padding: 0;
        }
    }
    .select-box-type {
        gap: 21px;
        border-radius: 3px !important;
        border: 0.35px solid #000;
        background: rgba(238, 239, 248, 0);
        .select__indicator {
            padding: 0;
        }
    }
}
.icon-button {
    width: 22px;
    height: 22px;
}
.cursor-default {
    cursor: default !important;
}
.button-dim {
    width: 85px;
    height: 24px;
}
.max-height-130 {
    max-height: 130px !important;
}
.max-height-62 {
    max-height: 62px !important;
}

.select-for-popup {
    .select-wrapper {
        position: relative;
    }

    .select__menu {
        position: absolute !important;
        width: 100% !important;
        margin-top: 4px !important;
        z-index: 10 !important;
    }

    .select__control {
        border: 1px solid #ccc !important;
        min-height: 32px !important;
        width: 100%;
    }

    .select__control:hover {
        border-color: #999 !important;
    }

    .select__control--is-focused {
        border-color: #2684ff !important;
        box-shadow: 0 0 0 1px #2684ff !important;
    }
}
/* Ensure dropdown is above other elements */
.select__menu-portal {
    z-index: 9999 !important;
}
