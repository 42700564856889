// my Positions
.my-position-main-container {
    background-color: $theme-mercury;
    height: 100%;
    width: 100%;
    padding: 1.8em 2.2em;
    overflow-y: auto;
    @media screen and (max-width: 992px) {
        padding: 1em 1em;
    }
    @media screen and (min-width: 1900px) {
        padding: 3em 3.5em;
    }
}

.postcard-main {
    @media screen and (max-width: 992px) {
        width: 100%;
    }
    @media screen and (min-width: 993px) {
        width: 48%;
        margin: 1%;
        display: inline-block;
    }
}

.pp-divider {
    border-bottom: 1.5px solid $theme-gray;
}

.myPositionTitle {
    color: $theme-blue;

    @media screen and (max-width: 992px) {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 1em;
    }
}

.myPositionContainer {
    // height: calc(100% - 90px);
    @media screen and (max-width: 992px) {
        max-width: 100%;
    }
}

.position-main-container {
    height: 100%;
    display: flex;

    .position-card-container {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        .position-table-header {
            background-color: $theme-light-blue;
            color: $theme-gray;
            padding: 15px 0;
            display: flex;
            align-items: center;
            height: 60px;
            margin-right: 10px;
            .double-arrow {
                height: 0.7em;
                margin-left: 10px;
            }
        }
        .position-table-row-container {
            height: calc(100% - 130px);
            overflow-y: auto;
            padding-right: 10px;
            .position-table-row {
                background-color: $White-Color;
                padding: 15px 0;
                display: flex;
                align-items: center;
                min-height: 60px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
                .position-table-row-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
                .jd-download-btn {
                    background-color: $theme-blue;
                    color: white;
                    border: none;
                    padding: 5px 10px;
                }
            }
        }
    }
    .card-image {
        height: 1.2em;
    }
}
.question-set-div {
    z-index: 1 !important;
}
.info-container {
    position: fixed;
    background-color: #fff;
    height: calc(100% - 60px);
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.5);
    right: 0;
    transition: all 0.5s ease-out;
    top: 60px;
    overflow-y: auto;
    background-color: $White-Color;

    @media screen and (min-width: 993px) {
        width: 43%;
    }

    @media screen and (max-width: 992px) {
        width: 100%;
    }

    .list {
        background-color: $White-Color;

        .title {
            font-size: 14px;
            padding-left: 10px;

            @media screen and (max-width: 480px) {
                width: 480px;
            }

            .btn-close {
                height: 0.4em;
                width: 0.4em;
            }
        }

        .table-list {
            width: 100%;
            text-align: start;

            @media screen and (max-width: 480px) {
                width: 480px;
            }

            td {
                padding: 1em;
            }

            .tl-header {
                td {
                    color: $theme-subuser;
                }
            }

            .tl-row {
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                .question-set-status {
                    width: 84px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    font-size: 10px;
                    margin: 0 auto;
                }
                .status-draft {
                    color: #12b6bc;
                    border: 1px solid #12b6bc;
                }
                .status-publish {
                    color: #133f7d;
                    border: 1px solid #133f7d;
                }
                .question-set-btn {
                    cursor: pointer;
                    height: 24px;
                    width: auto;
                    margin: 0 5px;
                }
                .list-id {
                    display: block;
                    margin: 0 auto;
                    text-align: center;
                }
                .list-id,
                .view-more {
                    padding: 5px;
                    background-color: $theme-blue;
                    border-radius: 0.4em;
                    color: $White-Color;

                    img {
                        height: 0.8em;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .my-position-feedback {
        background-color: $White-Color;
        color: $theme-placeholder;
        padding: 10px;
        .back-close {
            padding-left: 10px;
            height: 10px;
            .btn-close {
                height: 0.4em;
                width: 0.4em;
            }
        }

        .status-box {
            .status {
                color: $White-Color;
                background-color: $theme-green;
                background-color: #00c851;
                padding: 0.3em 1em;
                border-top-right-radius: 0.3em;
                border-bottom-right-radius: 0.3em;
            }
        }

        .fb-btn {
            padding: 0.6em 0;
            border-radius: 0.5em;
            margin: 2px;
            width: 110px;
            img {
                height: 1em;
                margin: 0 5px;
            }
        }
        .position-fb-btn {
            padding: 0.6em 1em;
            border-radius: 0.5em;
            margin: 2px;
            width: 124px;
            height: 30px;
            img {
                height: 1em;
                margin: 0 5px;
            }
        }

        .candi-name {
            color: #111;
        }
        .hr-divider {
            margin: 0.5rem 0;
        }
        .list-title {
            color: #111;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .list-info {
            color: $theme-placeholder;
            padding: 5px 20px;

            li {
                font-size: 12px;
                color: #8780ab;
            }
        }
        .divider {
            height: 1px;
            background-color: $theme-border;
        }
    }
}
.info-container.info-draft {
    @media screen and (min-width: 993px) {
        width: 700px;
    }
}
.candi-name,
.list-title {
    color: $Black-Color;
}
.candidate_color {
    color: #8780ab !important;
}
.question-set-div {
    background: #f6f7fb;
    padding: 30px !important;
}
.question-set-info {
    border-radius: 2px;
    background: #fff;
    color: #626262;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
    padding: 10px;
    margin: 10px;
}
.question-set-header {
    color: #191919;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.posCardContainer {
    width: 100%;
    background-color: $White-Color;
    color: $theme-placeholder;
    position: relative;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    cursor: pointer;
}
.posCardContainer.active {
    background: rgba(19, 63, 125, 0.15);
}

.position-id {
    color: $White-Color;
    background-color: $theme-blue;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    padding: 0.2em 1em;
}
.position-status-sticker {
    display: inline-block;
    color: $White-Color;
    padding: 0.2em 0;
    text-align: center;
    width: 8em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    margin-left: 5px;
}
.position-closed-status {
    background-color: #e96f68;
}
.position-active-status {
    background-color: #61be52;
}

.posCardHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .position-title-container {
        color: $theme-blue;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.posCardHeader_left {
    position: absolute;
    z-index: 1000;
    right: 10px;
    .position-no {
        margin-right: 20px;
        font-size: 15px;
        .position-no-img {
            height: 16px;
            width: 16px;
            margin-right: 5px;
        }
    }
}

.create-int-btn {
    position: absolute;
    border: none;
    background-color: $theme-blue;
    color: $White-Color;
    top: 48px;
    right: 10px;
    padding: 5px 10px;
    img {
        margin-right: 5px;
    }
}

.jd-img {
    height: 1.2em;
}

.boldHead {
    font-weight: bold;
}

.input-edit-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    width: 26px;
    cursor: pointer;
}

.sort-by {
    color: $theme-blue;
}

.sort-by-box {
    width: 25%;
    @media screen and (max-width: 992px) {
        width: 40%;
    }
    @media screen and (max-width: 580px) {
        width: 80%;
    }
}

.skip-btn {
    border: 1.4px solid $theme-blue;
    background-color: transparent;
    color: $theme-blue;
    padding: 7px;
    .skip-btn-img {
        height: 10px;
        width: auto;
        margin-left: 5px;
    }
}

.info-status {
    font-size: 16px;
    font-weight: 600;
    line-height: 24.59px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
    .status {
        font-size: 12px;
        height: 25px;
        width: 100%;
        padding: 0.3em 1em !important;
        display: flex;
        align-items: center;
        color: $White-Color;
        border-radius: 0px 5px 5px 0px;
        justify-content: center;
    }
    .pending,
    .invited,
    .reinvited {
        background: #9b9b9b;
        max-width: 80px;
    }
    .recommended {
        background: #18ac00;
    }
    .cautiously-recommended {
        background: #e8aa4e;
    }
    .not-recommended {
        background: #8b0000;
    }
    .position {
        background: #0086d2;
        max-width: 141px;
    }
}
