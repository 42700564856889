.DashboardContainer {
    padding: 20px;
    background-color: #f6f7fb;
}

.CreditDetailsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.CreditDetails {
    background-color: #ffffff;
    font-family: 'Manrope-SemiBold';
    padding: 5px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 24%;
    // margin: 0px 10px;
    border-radius: 5px;
    height: auto;
}
.CreditDetailsText {
    color: #12b6bc;
    font-size: 14px;
}
.CreditDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #454256;
    font-weight: 800;
}

.InterviewStatusContainer {
    font-family: 'Manrope-SemiBold';
    border-radius: 5px;
}
.InterviewStateTitle {
    font-size: 20px;
    color: #454256;
    text-align: center;
}
.InterviewStateDetails {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    padding: 0px 24px;
}
.TotalPostedInterviews {
    padding: 8px 10px 10px 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 100%;
}
.TotalPostedInterviews > div {
    color: #454256;
    font-family: 'Manrope-Bold';
    font-size: 16px;
    line-height: 1;
}
.TotalPostedInterviews > span {
    font-size: 36px;
    color: #133f7d;
}

.InterviewStatus {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 35%;
}
.PositionStatus {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 35%;
}
.InterviewStateContainer {
    width: 50%;
    background-color: #fff;
    border-radius: 5px;
}
.InterviewState {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 5px 5px;
}

.InterviewStateText div:first-child {
    font-size: 12px;
    color: #454256;
    font-family: 'Manrope-Bold';
}
.InterviewStateText-openSideBar{
    @media screen and (max-width: 1200px) {
        font-size: 10px !important;
        color: #454256;
        font-family: 'Manrope-Bold';
    }
    font-size: 12px;
    color: #454256;
    font-family: 'Manrope-Bold';
}
.InterviewStateText-openSideBar div:last-child {
    color: #0085f2;
    font-size: 10px;
}
.InterviewStateText div:last-child {
    color: #0085f2;
    font-size: 10px;
}
.ViewDetails{
    text-align: left;
    margin-top: 5px;
}
.PostedInterviewDetailNumber > span {
    font-weight: 300;
    font-size: 10px;
    color: rgba(0, 133, 242, 1);
}
.Red {
    color: #f2464b;
}
.Yellow {
    color: #e8aa4e;
}

.ProgressBarContainer {
    background-color: #ffffff;
    font-family: 'Manrope-SemiBold';
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    font-size: 16px;
    color: #454256;
    border: 8px #f6f7fb;
}
.progressBar {
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.GuageChartContainer,
.CircularChartContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 50%;
}

.GuageChart {
    width: 300px;
    margin-top: 10px;
}
.CircularChart {
    width: 135px;
    margin-top: 10px;
}

.SearchSelectContainer {
    font-family: 'Manrope-SemiBold';
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
}
.ClearFilters {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    color: #0b5dff;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0;
}
.SelectorsContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Manrope-SemiBold';
}
.Selectors {
    width: 23%;
}
.SelectorsLabel {
    color: #0c0c0d;
    font-weight: 600;
    margin: 10px 0;
}
.Selector {
    outline: none;
    border: none;
}
.filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    background-color: #eaebec;
    padding: 4px 8px;
    color: #133f7d;
    border-radius: 28px;
}
.btn-close {
    width: 5px;
    height: 5px;
    top: 2.5%;
    right: 1.5%;
}

.SubmitButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.SubmitButtonContainer .Submit {
    background-color: #133f7d;
    color: #fff;
    border: none;
    outline: none;
    padding: 10px 30px;
    border-radius: 5px;
}
.RadarChartContainer {
    background-color: #ffffff;
    font-family: 'Manrope-SemiBold';
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
}
.RadarChart {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 400px;
    margin-top: 20px;
}

.BarChartContaier {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    min-height: 400px;
}

.ChartContaier {
    margin-top: 20px;
    padding: 10px 0;
    font-family: 'Manrope-SemiBold';
}
.SingleChartContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 10px 0;
    font-family: 'Manrope-SemiBold';
    background-color: #ffffff;
}
.DoulbeChartContainer {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    // padding:10px 0;
}
.Chart {
    font-size: 12px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
    border-radius: 5px;
    width: 100%;
}
.DChart {
    width: 50%;
    background-color: #ffffff;
}

.SChart {
    border-radius: 5px;
    // padding:10px;
    width: 100%;
}
.BarBox {
    margin: 10px;
}
.ChartBox {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
// .BarChart{
//   padding:10px;
// }
.PieChart {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
}
.HorizontalBarChartContainer {
    margin-top: 20px;
}
.TitleText {
    text-align: center;
    margin-bottom: 20px;
}
.HorizontalBarBox {
    border-radius: 5px;
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff;
}
.graph_card {
    padding: 16px 9px 16px 9px;
    border-radius: 5px;
    background-color: #fff;
    .graph_title {
        font-size: 14px;
        line-height: 19.12px;
        letter-spacing: 0.75%;
        font-weight: 700;
        color: #000;
        text-align: center;
    }
}

.graph_card_position_feedback {
    padding: 8px 9px 8px 9px;
    border-radius: 5px;
    background-color: #fff;
    .graph_title {
        font-size: 14px;
        line-height: 19.12px;
        letter-spacing: 0.75%;
        font-weight: 700;
        color: #000;
        text-align: center;
    }
}
