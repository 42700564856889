.candidate-bank-form {
    display: flex;
    border: 0.5px solid #ccc;
    background: #ebedf9;
    padding: 10px 24px;
    gap: 20px;
    .cbf-input-box,
    .cbf-btn-box {
        width: 25%;
    }

    .cbf-btn-box {
        display: flex;
        justify-content: space-between;
        margin-top: 27px;
        .cbf-btn {
            font-size: 12px;
            border-style: none;
            padding: 1em;
            height: 42px;
            width: calc(50% - 10px);
            border-radius: 6px;
        }
        .cbf-save-btn {
            background-color: #1c4784;
            color: #ffffff;
        }
        .cbf-cancel-btn {
            background-color: transparent;
            border: 1.4px solid #1c4784;
            color: #1c4784;
        }
    }
}

.candidate-bank-table {
    min-width: 1140px;
    .candidate-bank-table-header {
        background-color: #e6e9f8;
        color: #626262;
        font-size: 12px;
        display: flex;
        align-items: center;
        height: 70px;
        .cbt-header-content {
            text-align: center;
            img {
                height: 8px;
                width: auto;
                margin-left: 10px;
            }
        }
    }
    .cbt-table-row {
        margin-top: 10px;
        display: flex;
        background-color: #ffffff;
        font-size: 12px;
        padding: 15px 0;
        align-items: center;
        height: 70px;
        padding: 15px 0;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        .cbt-table-row-content {
            padding: 0 10px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .cbt-view-candidate-btn {
                padding: 7px 12px;
                border-radius: 3px;
                background: #133f7d;
                color: #ffffff;
                font-size: 10px;
                border-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                margin: 0 auto;
                cursor: pointer;
                img {
                    height: 10px;
                    width: auto;
                    margin-right: 5px;
                }
            }
            .cbt-data-status {
                padding: 5px 10px;
                font-size: 10px;
                border-radius: 5px;
                width: 97px;
            }
            .added-status {
                border: 1px solid #18ac00;
                color: #18ac00;
            }
            .incomplete-status {
                border: 1px solid #f2464b;
                color: #f2464b;
            }
            .cbt-table-row-content-btn {
                height: 25px;
                width: auto;
                cursor: pointer;
            }
        }
    }
}
