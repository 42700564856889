.c-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $theme-blue;
    border: none;
    color: $White-Color;
    font-family: 'Manrope-Regular';
    padding: 1em;
}

.c-outline-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1.5px solid $theme-blue;
    color: $theme-blue;
    img {
        height: 1em;
        margin-left: 10px;
    }
}
.c-btn-small {
    max-height: 40px !important;
    // max-width: 80%;
}

.info-feedback {
    border-style: none;
    background-color: $theme-blue;
    color: $White-Color;
    height: 27px;
    padding: 0 10px;
    width: auto;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    img {
        height: 10px;
        width: auto;
        margin-left: 5px;
    }
    &:hover {
        color: white;
    }
}

.common-btn,
.save-as-draft {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
}

.save-as-draft {
    background-color: transparent;
    border: 1.5px solid #12b6bc;
    color: #12b6bc;
}

.common-btn {
    background-color: $theme-blue;
    border: 1.5px solid $theme-blue;
    color: $White-Color;
}
.common-btn.linkBtn {
    background-color: $theme-peacock;
    border-color: $theme-peacock;
}
.common-btn.cpp-btn.tab-btn {
    border-radius: 12px 12px 0px 0px !important;
    max-width: 150px;
    width: 100%;
    font-size: 13px;
    padding: 0.6em 0.6em;
}
.signin-signup-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: 'Manrope-Regular';
    padding: 1em;
    color: $White-Color;
}

.signin-blue {
    background-color: $theme-blue;
}

.signin-grey {
    background-color: $theme-subtitle-color;
}

.outline-btn {
    border: 1.5px solid $theme-blue;
    color: $theme-blue;
    padding: 0.6em 2em;
    border-radius: 0.5em;
}

.view-btn {
    border-style: none;
    background-color: $theme-light-blue;
    padding: 0.9em 0.7em;
    border-radius: 0.5em;
    right: 1%;

    img {
        width: 15px;
    }
}

.change {
    width: 100px;
    background-color: $White-Color;
}

//quick-create-btn//
.quick-create-container {
    position: relative;
    .quick-create-btn {
        border: none;
        border-radius: 3px;
        cursor: pointer;
        background-color: $theme-blue;
        color: $White-Color;
        height: 30px;
        padding: 18px;
        .quick-arrow {
            height: 10px;
            width: 10px;
            margin-left: 10px;
        }
    }
    .quick-btn-disabled {
        border: none;
        border-radius: 3px;
        cursor: not-allowed;
        background-color: #626262;
        color: #e7e7e7;
        height: 30px;
        padding: 18px;
        .quick-arrow {
            height: 10px;
            width: 10px;
            margin-left: 10px;
        }
    }
    .quick-dropdown {
        position: absolute;
        z-index: 1000;
        left: 0;
        top: 120%;
        background-color: $White-Color;
        padding: 5px 10px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        .quick-drop-divider {
            height: 0.5px;
            background-color: #f4f4f4;
            margin: 5px 0;
        }
        .quick-drop-option {
            padding: 5px 10px;
            width: 140px;
            border-radius: 5px;
        }
        .quick-drop-option-enabled {
            cursor: pointer;
            color: $theme-blue;
            &:hover {
                background-color: $theme-light-blue;
            }
        }
        .quick-drop-option-disabled {
            cursor: not-allowed;
            background-color: #e9e9e9;
            color: #6f6f84;
        }
    }
}
//quick-create-btn//

//convert-btn-css//
.convert-btn {
    border: 1.5px solid $theme-blue;
    background-color: none;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    .convert-btn-img {
        width: 16px;
        height: 16px;
    }
}
//convert-btn-css//

.clock-filter-logo {
    height: 38px;
    width: auto;
}
.common-btn.linkBtn {
    background-color: $theme-peacock;
    border-color: $theme-peacock;
}
.common-btn.linkBtnDisabled {
    background-color: #e9e9ea;
    border-color: #e9e9ea;
    color: #000000;
}
