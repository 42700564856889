.threedot-btn-wrapper {
    .threedot-btn {
        border-style: none;
        background-color: $theme-light-blue;
        height: 2.4em;
        width: 2.4em;
        img {
            height: 1em;
        }
    }

    .threedot-menu {
        background-color: $White-Color !important;
        top: 100%;
        z-index: 100;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 0px !important;
        border: none !important;
        .threedot-menu-content {
            height: 2.5em;
            width: 15em;
            cursor: pointer;
            color: $theme-subuser;
            &:hover {
                background-color: $theme-light-blue;
                color: $theme-blue;
            }
            @media screen and (max-width: 992px) {
                width: 10em;
            }
        }
    }
}

.invite-btn-wrapper {
    position: relative;
    .invite-btn {
        height: 2.4em;
        border-radius: 0.5em;
        width: 100px;
        cursor: pointer;
        color: $White-Color;
        display: flex;
        overflow: hidden;
        .invite-btn-name-box {
            background-color: #12b6bc;
            width: 80px;
            .tick-img {
                height: 7px;
                width: auto;
                margin-left: 5px;
            }
        }
        .invite-btn-arrow-box {
            background-color: #edebeb;
            width: 20px;
        }
    }
    .send-invite-option {
        cursor: pointer;
        color: $theme-subuser;
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
            height: 16px;
            width: auto;
            margin-right: 5px;
        }
    }
}

.invite-btn-wrapper-2 {
    position: relative;
    z-index: 1;
    margin-right: 16px;
    .invite-btn {
        height: 2.4em;
        border-radius: 0.5em;
        background-color: $theme-blue;
        width: 80px;
        cursor: pointer;
        color: $White-Color;
    }

    .send-invite-dropdown {
        position: absolute;
        right: 0px;
        background-color: $White-Color;
        z-index: 100;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

        .send-invite-option {
            width: 180px;
            padding: 5px;
            cursor: pointer;
            color: $theme-subuser;
            border-radius: 5px;
            margin: 10px;

            &:hover {
                background-color: $theme-light-blue;
                color: $theme-blue;
            }

            img {
                height: auto;
            }

            span {
                width: 160px;
                font-size: 16px;
            }
        }
    }
}
