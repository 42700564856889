.sidebar-main {
    background-color: $theme-blue;
    height: 100%;
    z-index: 999;
    width: 100%;
    padding: 0 1em;
    // @media screen and (min-width: 1400px) {
    //   padding: 0 2em;
    // }
    img {
        width: 1em;
        cursor: pointer;
    }
}

.sidebar-main-container {
    background-color: $theme-blue;
    height: 100%;
    z-index: 999;
    position: relative;
    img {
        width: 1.5em;
        pointer-events: none;
    }

    .sidebarCollapse {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: $theme-peacock;
        position: absolute;
        top: 5em;
        right: -0.8em;
        z-index: 999999999;
        padding: 2px;
        cursor: pointer;
    }
}

// .active-sidebar {
//   background-color: $theme-blue;
//   border-left: 1px solid $White-Color;
//   height: 100%;
//   width: 300px;
//   position: absolute;
//   left: 60px;
//   bottom: 0;
//   transition: 0.3s all linear;
//   padding-top: 70px;
// }

// .inactive-sidebar {
//   background-color: $theme-blue;
//   border-left: 1px solid $White-Color;
//   height: 100%;
//   width: 100%;
//   // position: absolute;
//   // left: 60px;
//   // bottom: 0;
//   transition: 0.1s all linear;
//   border: none;
// }

.collapse-padding {
    padding: 0 20px;
    .active {
        background: #ffffff;
        border-radius: 50%;
    }
}

.sidebar-collapse-header {
    display: flex;
    background-color: $theme-blue;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    padding: 4px;
}

.sidebar-collapse-header:hover {
    background-color: #ffffff;
    color: $theme-blue;
    cursor: pointer;
    svg > path {
        fill: $theme-blue;
    }
}

.division-div {
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    margin: 5px 0px;
}

.product-icon-container {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.sidebar-header {
    display: flex;
    width: 100%;
    height: 44px;
    background-color: $theme-blue;
    color: #ffffff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
}

.sidebar-header:hover {
    background-color: #ffffff;
    color: $theme-blue;
    cursor: pointer;
    svg > path {
        fill: $theme-blue;
    }
}

.go-to-product {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    padding: 5px 0px;
}

.gtp-img {
    width: 14px !important;
    margin-right: 10px;
}

.active-sidebar-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
    /* Track */
    &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $White-Color;
    }

    .my-services-container,
    .other-services-container {
        color: $White-Color;
        svg {
            width: 1.5em;
            height: auto;
            margin-right: 10px;
        }
        .active {
            background: #ffffff;
            color: #123a75;
        }
        .services {
            img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
            }
            padding: 5px;
            margin: 10px 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
                background-color: $White-Color;
                color: $theme-blue;
                svg > path {
                    fill: $theme-blue;
                }
            }
        }
        .active {
            background-color: $White-Color;
            color: $theme-blue;
        }
    }

    .invite-new-member {
        border-top: 2px solid $White-Color;
        svg {
            height: 1.5em;
            margin-right: 10px;
        }
        .title {
            margin: 10px 0;
            color: $White-Color;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
    }
}

.sidebar-back-arrow,
.sidebar-right-arrow {
    stroke: $White-Color;
    fill: $White-Color;
    height: 0.8em;
    width: auto;
}

.mobile-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $theme-blue;
    display: flex;
    flex-direction: column;
    // overflow-x: hidden;
    // overflow-y: auto;
    z-index: 1000;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme-mist;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: none;
    }
    color: $White-Color;
    .profile-container {
        height: 100px;
        width: 100px;
        overflow: hidden;
        .profile-img {
            width: 100%;
            height: auto;
        }
    }
    .notification-container {
        height: auto;
        position: relative;
        .notify {
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $theme-error;
            right: 0;
            top: 0;
        }
    }
    .profile-box {
        .name {
            font-size: 25px;
        }
        .id {
            font-size: 16px;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 10px 0;
        display: block;
        li {
            font-size: 16px;
            display: flex;
            align-items: center;
            height: 2.5em;
            border-radius: 0.4em;
            &:hover {
                background-color: $theme-mist;
            }
            img {
                width: 1em;
                height: 1em;
                margin: 0 10px;
            }
        }
    }
    .services {
        font-size: 20px;
        margin: 10px 0 0 10px;
    }
    .close-side-img {
        width: 30px;
        height: 30px;
        position: absolute;
        right: -15px;
        top: 10px;
        cursor: pointer;
    }
}

.mob-divider {
    border-top: 2px solid $White-Color;
}

.side-open {
    width: 340px;
    // padding: 30px;
}
.side-close {
    width: 0;
    padding: 0;
}
.side-close img {
    display: none;
}
.sidebar-over {
    overflow: auto;
    padding: 30px;
    @media screen and (max-width: 420px) {
        padding: 15px;
    }
}
.side-close .sidebar-over {
    padding: 0px;
}
.p-25 {
    padding: 0 20px;
}

.active-sidebar-downdiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* Track */
    &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $White-Color;
    }
}
.interogation-main {
    cursor: pointer;
    color: $White-Color;
    padding-top: 8px;
    border-top: 1px solid #ffffff;
    svg {
        height: 27px;
        width: 27px;
    }
}
.interogation-main-active {
    border-top: 1px solid #ffffff;
    svg {
        height: 27px;
        width: 27px;
    }
}

.support-width {
    width: 210px;
    margin: 4px auto 0px auto;
    svg {
        margin: 0 10px;
    }
    &:hover {
        background-color: $White-Color;
        color: $theme-blue;
        svg > path {
            fill: $theme-blue;
        }
    }
}
.support-width1 {
    width: 210px;
    margin: 4px auto 0px auto;
    svg {
        margin: 0 10px;
    }
    &:hover {
        background-color: $White-Color;
        color: $theme-blue;
        svg > circle {
            fill: $theme-blue;
        }
    }
}

.product-indicator {
    .product-icon-container {
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
}
