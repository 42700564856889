.cpp-wrapper {
    .bulk-requirement-details {
        .bulk-requirement-info-table {
            td {
                padding: 8px;
            }
            tr:nth-child(even) {
                background-color: #f4f4f4;
            }

            tr:nth-child(odd) {
                background-color: #ffffff;
            }
        }
        .bulk-requirement-details-btn-container {
            display: flex;
            align-items: center;
            .bulk-requirement-details-btn {
                border-radius: 4px;
                padding: 5px 10px;
                color: #ffffff;
                border: none;
                background-color: #133f7d;
                .cloud-download-logo {
                    width: 12px;
                    height: auto;
                    margin-right: 10px;
                }
            }
        }
    }
    .bulk-requirement-table {
        .bulk-requirement-table-header {
            background-color: #e6e9f8;
            display: flex;
            font-size: 12px;
            padding: 15px 0;
            .bulk-requirement-table-header-content {
                img {
                    height: 8px;
                    width: auto;
                    cursor: pointer;
                }
            }
        }
        .bulk-requirement-table-body {
            .bulk-requirement-table-row {
                display: flex;
                background-color: #ffffff;
                padding: 15px 0;
                .bulk-requirement-table-row-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
