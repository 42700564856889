.calender-invite {
    height: 500px;
    width: 600px;
    background-color: $White-Color;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.4em;
    background-color: #f6f7fb;

    .calender-invite-mail-body {
        background-color: white;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .mail-link {
            background-color: white;
            word-wrap: break-word;
            padding: 10px;
            color: #565656;
            border: none;
            background-color: lightgray;
            resize: none;
        }
        .mail-body {
            background-color: white;
            word-wrap: break-word;
            padding: 10px;
            color: #565656;
            border: none;
            resize: none;
            &:focus {
                outline: none;
            }
        }
    }

    .label {
        color: $theme-placeholder;
    }

    .candidate,
    .interviewer {
        display: flex;
        justify-content: center;
        align-items: center;
        input {
            cursor: pointer;
        }
    }

    .ci-title-box {
        color: $theme-blue;
        width: 100%;
        .ci-close-btn {
            width: 0.3em;
            height: 0.3em;
        }
    }
    .calender-invite-content {
        height: 90%;
        overflow-y: auto;
        width: 100%;
        padding: 0 20px;
        .gray-border {
            border: 1px solid #cccccc;
        }
    }
    .ci-input-box {
        .ci-role-input {
            height: 2.5em;
            width: 90%;
            padding-left: 0.5em;
            border-style: none;
            border-bottom: 1px solid $theme-placeholder;
            color: $theme-blue;
        }

        .ci-datetime-input {
            height: 2.5em;
            width: 90%;
            border-style: none;
            padding-left: 0.5em;
            border: 1px solid $theme-placeholder;
            color: $theme-blue;
        }

        .ci-mail-input {
            height: 15em;
            width: 90%;
            border-style: none;
            padding: 0.5em;
            border: 1px solid $theme-placeholder;
            color: $theme-blue;
        }

        .ci-to-input,
        .ci-guest-input {
            height: 2.5em;
            width: 90%;
            padding-left: 0.5em;
            border-style: none;
            border: 1px solid $theme-placeholder;
            color: $theme-blue;
        }
    }

    .ci-invite-btn {
        padding: 0.6em 1.2em;
    }
    .ReactTags__tagInputField {
        width: 100%;
        border: none;
        padding: 1.2em;
        &:focus {
            outline: none;
        }
    }
}

.interview-card-main {
    @media screen and (min-width: 993px) {
        width: 48%;
        display: inline-block;
        margin: 1%;
    }
}
.custom-container {
    background-color: #f3f3f3;
    padding: 8px 16px 16px 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.main-card-container {
    .card-container {
        overflow-x: auto;
        width: 100%;
        @media screen and (max-width: 992px) {
            padding-right: 0;
            overflow: initial;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $theme-blue;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $theme-blue;
        }

        .interview-card.active {
            background: rgba(19, 63, 125, 0.15);
        }
        .interview-card {
            position: relative;
            height: auto;
            cursor: pointer;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            background-color: $White-Color;
            .interview-card-btn-container {
                display: flex;
                justify-content: flex-end;
                position: absolute;
                top: 10px;
                right: 16px;
            }
            .card-image {
                height: 1.2em;
            }

            .card-number,
            .job-role,
            .resume,
            .job-description,
            .interview-time {
                color: $theme-placeholder;
                font-weight: 500;
                margin-bottom: 4px;
            }

            .interview-score {
                border-radius: 0.3em;
                padding: 2.5px 10px;
                color: $White-Color;
                letter-spacing: 1px;
            }

            .excellent-score {
                border: 1.5px solid #18652e;
                background-color: #18652e;
            }

            .very-good-score {
                border: 1.5px solid #32cd32;
                background-color: #32cd32;
            }

            .good-score {
                border: 1.5px solid #74c365;
                background-color: #74c365;
            }

            .average-score {
                border: 1.5px solid #e8aa4e;
                background-color: #e8aa4e;
            }

            .poor-score {
                border: 1.5px solid #ff9f43;
                background-color: #ff9f43;
            }

            .very-poor-score {
                border: 1.5px solid red;
                background-color: red;
            }

            .card-main-content {
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                // position: relative;
                .card-key {
                    color: $theme-blue;
                    .copy-img {
                        height: 12px;
                        width: auto;
                    }
                }
                .card-name {
                    top: 10%;
                    left: 0;
                    margin-right: 10px;
                    max-width: 140px;
                    cursor: pointer;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .threedot-btn-wrapper {
                    @media screen and (max-width: 992px) {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }

        .feedback-btn {
            border-style: none;
            background-color: $theme-blue;
            color: $White-Color;
            padding: 0.6em;
            border-radius: 0.5em;
            margin-right: 10px;
            height: 2.4em;
            img {
                height: 1em;
                font-size: 10px !important;
            }
        }

        .c-invite {
            margin-right: 10px;
            border-radius: 0.5em;
            z-index: 2000;
        }

        .i-invite {
            margin-right: 10px;
        }

        .replicate-btn {
            border-style: none;
            border: 1.5px solid #23a566;
            border-radius: 0.5em;
            color: #23a566;
            background-color: transparent;
            padding: 0.6em;
            height: 2.4em;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            img {
                height: 1em;
                vertical-align: initial !important;
                font-size: 10px !important;
                margin-left: 5px;
            }
        }

        .interviewer-card {
            height: auto;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            background-color: $White-Color;
            .gray-text {
                color: $theme-placeholder;
                display: flex;
                align-items: center;
                img {
                    height: 1em;
                }
                .cloud {
                    margin-left: 5px;
                }
                .doc {
                    margin: 0 5px 0 10px;
                }
                .clock {
                    margin-right: 5px;
                }
            }
            .reject-interview {
                color: $theme-red;
                margin-bottom: 5px;
                cursor: pointer;
            }
            .bid-btn {
                border-radius: 0.4em;
                padding: 0.5em 1em;
                background-color: $White-Color;
                img {
                    margin-right: 10px;
                    height: 1em;
                }
            }
            .upload-btn {
                border-radius: 0.4em;
                width: 12em;
                height: 2em;
                border-radius: 0.4em;
                background-color: $White-Color;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row-reverse;
                margin-bottom: 10px;
                img {
                    margin-left: 10px;
                    height: 1em;
                    width: 1em;
                }
            }
            .ss-dropdown {
                border-radius: 0.4em;
                padding: 10px;
                background-color: $White-Color;
                width: 100%;
                z-index: 1;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
                span {
                    padding: 0.5em;
                    color: $theme-subuser;
                    border-radius: 0.4em;
                    cursor: pointer;
                    &:hover {
                        background-color: $theme-light-blue;
                        color: $theme-blue;
                    }
                }
            }
            .bid-blue {
                border: 1.5px solid $theme-blue;
                color: $theme-blue;
            }
            .bid-red {
                border: 1.5px solid $theme-red;
                color: $theme-red;
            }
            .current-bid {
                .peacock {
                    .bid-dot {
                        background-color: $theme-peacock;
                    }
                    .bid-price {
                        color: $theme-peacock;
                    }
                }
                .error {
                    .bid-dot {
                        background-color: $theme-red;
                    }
                    .bid-price {
                        color: $theme-red;
                    }
                }
                .bid-dot {
                    height: 0.8em;
                    width: 0.8em;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                .time-left-bid {
                    margin-left: 5px;
                    color: $theme-gray;
                }
            }
            .interview-btn {
                flex-direction: row-reverse;
                width: 12em;
                height: 2em;
                border-radius: 0.4em;
                margin-right: 15px;
                margin-bottom: 10px;
                img {
                    height: 1em;
                    margin-left: 10px;
                }
            }
            .feedback-report-box {
                .feedback-btn {
                    background-color: $theme-light-blue;
                    height: 2em;
                    width: 2em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-style: none;
                    border-radius: 0.4em;
                    margin: 5px;
                    img {
                        height: 1em;
                    }
                }
            }
        }
        .my-interviews-table-header {
            background-color: $theme-light-blue;
            display: flex;
            align-items: center;
            height: 70px;
            min-width: 1140px;
            .double-arrow {
                height: 0.7em;
                margin-left: 10px;
            }
        }
        .my-interviews-table-content {
            min-width: 1140px;
            .my-interviews-table-row {
                padding: 15px 0;
                display: flex;
                align-items: center;
                height: 70px;
                padding: 15px 0;
                background-color: #ffffff;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
            }
        }
    }

    .info-main-container {
        padding: 30px 25px 25px 25px;
        flex: 1;
        max-width: 38%;
        background-color: white;
        .info-scroll-container {
            height: 100%;
            overflow-y: auto;
        }
        @media screen and (max-width: 992px) {
            max-width: 100%;
        }
    }

    .info-container {
        .info-box {
            .label-box {
                .label {
                    color: white;
                    padding: 0.3em 1em;
                    border-bottom-right-radius: 0.3em;
                    border-top-right-radius: 0.3em;
                    margin-right: 20px;
                    background-color: green;
                }
            }

            .feedback-box {
                .info-id {
                    color: $theme-placeholder;
                }
            }

            .name-info {
                color: $theme-placeholder;
            }
        }

        .divider-box {
            border-bottom: 1px solid $theme-placeholder;
        }

        .work-experiance-box {
            .work-experiance-info {
                color: $theme-placeholder;
                font-weight: 500;
            }
        }
    }
    // bulk upload css //
    .bulk-upload-title {
        height: 18px;
    }
    .bulk-upload-table {
        height: 300px;
        .w-8 {
            width: 8%;
        }
        .w-16 {
            width: 16.8%;
        }
        .bu-header {
            background-color: $theme-light-blue;
            padding: 15px 10px;
            margin: 5px 10px 0 0;
            .bu-header-content {
                color: $theme-arrow;
                img {
                    height: 0.6em;
                    margin-left: 5px;
                }
            }
        }
        .bu-content-container {
            height: calc(100% - 60px);
            padding-right: 10px;
            overflow-y: auto;
            .bu-content {
                margin-top: 10px;
                padding: 15px 10px;
                background-color: $White-Color;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            }
        }
    }
    // bulk upload css //
}

/* feedback report css */
.download-pdf {
    width: 1em;
}

.candi-feedback-box {
    height: 100px;
}

.candi-performance {
    height: 90%;
    width: 100px;
    svg {
        height: 100%;
    }
}

.no-data-image {
    height: 200px;
    width: auto;
    margin-top: 40px;
}

.man-skill-box {
    height: auto;
    border: 1.5px solid $theme-light-blue;
}

.performance-review {
    width: 70%;
}

.skill-performance {
    height: 70px;
    width: 70px;
    margin-top: 4px;
    svg {
        height: 100%;
    }
}

.dot {
    height: 0.3em;
    width: 0.3em;
    border-radius: 50%;
}

.dot-review-red {
    color: $theme-red;
}

.dot-review-green {
    color: $theme-green !important;
}

.dot-review-yellow {
    color: $theme-yellow !important;
}

.skill-review-box,
.final-remarks {
    height: auto;
    width: 100%;
    padding: 10px;
    border: 1px solid $theme-placeholder;
    color: $theme-placeholder;
    text-align: justify;
}
// .sef-container {
//   height: 2em;
//   @media screen and (max-width: 992px) {
//     width: 100%;
//   }
// }

.info-btn-close {
    width: 5px;
    height: 5px;
    top: 10px;
    right: 10px;
}

.red {
    background-color: $theme-red !important;
}

.green {
    background-color: $theme-green !important;
}

.yellow {
    background-color: $theme-yellow !important;
}

.carrot {
    background-color: $theme-carrot !important;
}

.grey {
    background-color: $theme-subuser !important;
}

.id-sticker,
.status-sticker {
    display: inline-block;
    color: $White-Color;
    padding: 0.2em 0;
    text-align: center;
    width: 8em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.status-sticker {
    margin-left: 5px;
}

.id-sticker {
    background-color: $theme-blue;
}

.meeting-link-btn {
    border-style: none;
    background-color: $theme-light-blue;
    font-size: 10px;
    margin-right: 10px;
    padding: 5px;
    color: $theme-blue;
    height: 2.4em;
    width: 2.4em;
    .copy-link {
        width: 10px;
        height: auto;
    }
}

// .card-btn-list {
//   right: 0;
//   position: absolute;
//   margin-top: 30px;
//   z-index: 99;
// }

.info-main-container {
    position: fixed;
    background-color: #fff;
    height: calc(100% - 60px);
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.5);
    right: 0;
    padding: 0 12px 12px;
    transition: all 0.5s ease-out;
    z-index: 10000;
    top: 60px;
    @media screen and (min-width: 993px) {
        width: 50%;
    }
    @media screen and (max-width: 992px) {
        width: 100%;
    }
}

.invite-div-join-meet {
    background-color: $theme-light-blue;
    border: none;
    margin-right: 10px;
    height: 2.4em;
    width: 2.4em;
    cursor: pointer;
    .join-meet-img {
        height: 40%;
        width: auto;
    }
}

.interview-card a:hover {
    color: #ffffff;
}
.calender-invite-content .w-47 {
    min-width: 90px;
}

.feedback-download-btn {
    border: none;
    background-color: $theme-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $White-Color;
    .feedback-download-arrow {
        height: 13px;
        width: auto;
    }
}

.final-rating-text {
    text-align: center;
    width: 100px;
}

.interview-status-tab {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    width: auto;
    .status-dot {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .cancel-reason-img {
        height: 10px;
        width: 10px;
        margin-left: 5px;
    }
}

.status-type-1 {
    background: rgba(19, 63, 125, 0.1);
    color: #133f7d;
    .dot-color {
        background-color: #133f7d;
    }
}
.status-type-2 {
    background: rgba(18, 182, 188, 0.1);
    color: #12b6bc;
    .dot-color {
        background-color: #12b6bc;
    }
}
.status-type-3 {
    background: #ebf5eb;
    color: #18ac00;
    .dot-color {
        background-color: #18ac00;
    }
}
.status-type-4 {
    background-color: #f0f0f2;
    color: #626262;
    .dot-color {
        background-color: #626262;
    }
}
.status-type-5 {
    background-color: #fef2f2;
    color: #f2464b;
    .dot-color {
        background-color: #f2464b;
    }
}
.status-type-6 {
    background-color: #fff6f0;
    color: #ff822e;
    .dot-color {
        background-color: #ff822e;
    }
}

.status-sticker-type-1 {
    background-color: #133f7d;
}
.status-sticker-type-2 {
    background-color: #12b6bc;
}
.status-sticker-type-3 {
    background-color: #18ac00;
}
.status-sticker-type-4 {
    background-color: #626262;
}
.status-sticker-type-5 {
    background-color: #f2464b;
}
.status-sticker-type-6 {
    background-color: #ff822e;
}
.main-card-container .card-container .feedback-btn.disable {
    background-color: #a9a9ab;
}
