.excel-btn {
    border-style: none;
    background-color: $theme-blue;
    padding: 5px 10px;
    height: 35px;
    color: $White-Color;
    box-sizing: border-box;
    img {
        height: 12px;
        width: auto;
        margin-left: 10px;
    }
}
