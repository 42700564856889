.file-input {
    border: 0.1px dashed $theme-blue;
    position: relative;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    font-size: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    .real-file-input {
        position: absolute;
        visibility: hidden;
    }
    .fake-file-input {
        width: calc(100% - 20px);
        height: 100%;
        z-index: 2;
        background-color: #ffffff;
        padding: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .fake-placeholder {
            color: #918ab2;
        }
    }
    .file {
        z-index: 1;
        height: 80%;
        right: 0;
        bottom: 12%;
    }
    .close-mark {
        top: 37.5%;
        right: 5%;
        height: 25%;
        cursor: pointer;
        z-index: 1;
    }
}
.file-error {
    box-shadow: none;
    border: 2px solid #ed184f !important;
}
.upload-type {
    color: $theme-placeholder;
}

//bulk-file-input//
.blue-border {
    border: 1.5px solid $theme-blue;
}
.no-border {
    border: 1.5px solid transparent;
}
.bulk-file-input {
    height: 40px;
    width: 130px;
    input {
        width: 100%;
        height: 100%;
        z-index: 1;
        color: transparent;
    }
    .input-status {
        left: 10%;
        padding: 5px;
    }
    .file-empty {
        color: $theme-blue;
    }
    .file-present {
        color: $White-Color;
        background-color: $theme-blue;
        border-radius: 0.5em;
    }
    .upload-file {
        z-index: 0;
        height: 40%;
        right: 10%;
    }
    .red-trash {
        right: 10%;
        height: 45%;
        cursor: pointer;
        z-index: 1;
    }
}
//bulk-file-input//
